<template>
<div>
  <b-container>
    <b-row class="mb-4" v-if="!firstRun">
      <b-col>
        <div v-if="active">
          <b-spinner small variant="success" type="grow" label="Spinning"></b-spinner><small>live updates on</small>
        </div>
        <b-card class="mb-3">
          <router-link class="mr-2 btn btn-outline-primary" :to="{ name: 'NewsAdvancedFilter' }">Filter</router-link>
        </b-card>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            :propItem="item"
            :key="item.id"
            :tagsProp=tags
            :username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
             />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'

import ac from '../libs/accesscontrol'
import Publication from '@/components/PublicationNew'

export default {
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "KPMG Regulatory Horizon: News Feed"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'news', action: 'open news' })
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.load()
    _.delay(this.refresh, this.delay)
  },
  data () {
    return {
      active: true,
      authToken: '',
      delay: 60 * 1000,
      firstRun: true,
      formEventValidated: false,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      limit: 20,
      loadingNews: true,
      news: [],
      offset: 0,
      page: 0,
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      tags: []
    }
  },
  beforeDestroy: function () {
    this.active = false
  },
  methods: {
    load: async function () {
      this.$logger.debug('load started')
      this.offset = this.page * this.limit
      try {
        let apiName = 'cosmos'
        let path = `/news/limit/${this.limit}/offset/${this.offset}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug(response)
        let news = response
        // push into existing array
        for (let i = 0, len = news.length; i < len; i++) {
          this.news.push(news[i])
        }
        this.page = this.page + 1
        this.firstRun = false
        this.loadingNews = false
        this.$store.commit('setWindowBottom', false)
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    refresh: async function () {
      if (!this.active) {
        return
      }
      this.$logger.debug('refresh started')
      try {
        let apiName = 'cosmos'
        let path = `/news/limit/20/offset/0`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug(response)
        let news = response
        _.reverse(news)
        _.forEach(news, (x) => {
          let exist = _.find(this.news, ['id', x.id])
          if (!exist) {
            x.refreshed = true
            this.news.unshift(x)
          }
        })
      } catch (e) {
        this.$logger.warn('refresh error: ' + e)
      }
      if (this.active) {
        _.delay(this.refresh, this.delay)
      }
    },
    windowBottomTrigger: function () {
      this.$logger.debug('window bottom triggered: ' + this.$store.state.windowBottom)
      if (this.$store.state.windowBottom && !this.loadingNews && !this.firstRun) {
        this.$store.commit('setWindowBottom', false)
        this.loadingNews = true
        this.$stat.log({ page: 'news', action: 'load more news' })
        this.load()
      }
    }
  },
  watch: {
    '$store.state.windowBottom': 'windowBottomTrigger',
    'loadingNews': 'windowBottomTrigger'
  }
}
</script>

<style>
</style>
